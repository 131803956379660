/**
* Generated automatically at built-time (2024-08-23T13:50:08.364Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "outdoor-kitchen-collection-overview",templateKey: "sites/101-e0f5d650-6459-4e1c-8b91-49a59c60bb6a"};
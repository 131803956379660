/**
* Generated automatically at built-time (2024-08-23T13:50:08.404Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "8dd9bbc3-2e8e-4438-bc66-caafce9b0b20",templateKey: "sites/101-b6389ef9-a290-48c1-8bba-7891477eb505"};